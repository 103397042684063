export const skillsData = [
    {
      id: 1,
      skillName: 'Javascript',
    },
    {
      id: 2,
      skillName: 'Data cleaning',
    },
    {
      id: 3,
      skillName: 'Statistic',
    },
    {
      id: 4,
      skillName: 'Python',
    },
    {
      id: 5,
      skillName: 'C++',
    },
    {
      id: 6,
      skillName: 'C#',
    },
    {
      id: 7,
      skillName: 'PHP',
    },
    {
      id: 8,
      skillName: 'React.js',
    }
  ];