export const data = [
    {
      id: 1,
      jobTitle: 'Software Developer',
      location: 'Seattle, WA',
      long: -122.335167,
      lat: 47.608013,
      company: 'Apple',
      skills: ['Java', 'C']
    },
    {
        id: 2,
        jobTitle: 'Java Developer',
        location: 'New York, NY',
        long: -73.935242,
        lat: 40.730610,
        company: 'Chase',
        skills: ['Java', 'PHP','JSP','JQuery']
    },
    {
        id: 3,
        jobTitle: 'Front-End Developer',
        location: 'San Fransisco, CA',
        long: 122.4194,
        lat: 37.7749,
        company: 'M&G',
        skills: ['Javascript', 'CSS']
    },
    {
        id: 4,
        jobTitle: 'Database Manager',
        location: 'San Fransisco, CA',
        long: 122.4194,
        lat: 37.7749,
        company: 'Database Technologies',
        skills: ['MySQL', 'MongoDB','Data cleaning']
    }
  ];