export const skillWordData = [
    {skill: "Java", frequency: "85.3"}, 
    {skill: "SQL", frequency: "81.5"}, 
    {skill: "JavaScript", frequency: "50.1"}, 
    {skill: "C#", frequency: "49.6"}, 
    {skill: "CSS", frequency: "43.4"},
    {skill: "HTML", frequency: "43.3"}, 
    {skill: "Agile", frequency: "35.7"},
    {skill: "Oracle", frequency: "351."}, 
    {skill: "Python", frequency: "34.3"},
    {skill: "XML", frequency: "28.6"}, 
    {skill: "C++", frequency: "27.6"},
    {skill: "J2EE", frequency: "26.8"}, 
    {skill: "SQL Server", frequency: "24.4"},
    {skill: "Windows", frequency: "23.9"}, 
    {skill: "Project Management", frequency: "23.7"},
    {skill: "AWS", frequency: "23.1"}, 
    {skill: "HTML5", frequency: "22.1"}, 
    {skill: "Spring", frequency: "21.3"}, 
    {skill: "Cisco", frequency: "19.3"}, 
    {skill: "Hadoop", frequency: "19.3"},
    {skill: "PHP", frequency: "18.8"}, 
    {skill: "Security", frequency: "18.7"},
    {skill: "SDLC", frequency: "18.2"}, 
    {skill: "ETL", frequency: "17.7"},
    {skill: "ASP.NET", frequency: "16.8"}, 
    {skill: "AngularJS", frequency: "16.2"},
    {skill: "QA", frequency: "16.8"}, 
    {skill: "Development", frequency: "16.1"},
 
];
