export const data = [
    {
      id: 1,
      username: 'johndow1',
      lastName: 'Dow',
      firstName: 'John',
      location: 'Seattle, WA',
      jobTitle: 'Software Developer',
      password: 'monkey123',
      skills: ['Java', 'C']
    }
  ];